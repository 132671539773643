import _ from 'lodash';
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { Container, Row, Col, Button,Modal } from 'react-bootstrap';
import { navigate } from '@reach/router';
// import { Row, Col, Form, Modal, Container, Button } from 'react-bootstrap';

import { Store } from '../../Services';
import Controller from '../../Controller';

import App from '../../Services/App';
import Permission from '../../helper/Permission';

import './ElementPage.scss';
import UsersActions from '../../Services/Users';

const DeleteUserPanel = ({ id, setViewDeleteUser }) => {

  const deleteUser = async () => {
        UsersActions.remove(id).then(() => {
          App.addNotification({ message: 'Usuario eliminado exitosamente', variant: 'success' });
          setViewDeleteUser(false);
        });
  };
  const handleCancel = async () => {
          setViewDeleteUser(false);
  };
  return (
    <Container fluid>
      <Row>
        <Col xs="12" style={{ paddingTop: '1.5rem' }}>
          <div className="d-flex justify-content-center">
            <Button  onClick={deleteUser} style={{marginRight:'10px'}}variant="secondary">
              Eliminar
            </Button>
            <Button onClick={handleCancel} variant="secondary">
              Cancelar
            </Button>
          </div>
        </Col>
        <Col xs="6" style={{ paddingTop: '1.5rem' }}>
        </Col>
      </Row>
    </Container>
  );
};


export default ({ _id, Panel, model, canDelete = false, className }) => {
  const { state, dispatch } = useContext(Store);

  const permission = useMemo(() => Permission.has(state.role), [state.role]);
  const childRef = useRef();

  const [disabled, setDisabled] = useState(!!_id);

  const [isContainerFluid, setIsContainerFluid] = useState();
  const [controlButtons, setControlButtons] = useState();
  const [controlButtonsStd, setControlButtonsStd] = useState();

  const [controller, setController] = useState();
  const [selected, setSelected] = useState();
  const [viewDeleteUser, setViewDeleteUser] = useState(false);


  useEffect(() => {
    setController(Controller(model));
  }, [model]);

  const onDelete = async () => {
    setDisabled(true);
    try {
      await controller.remove(_id, { throwError: true });
      App.addNotification({ message: 'Eliminado exitosamente', variant: 'success' });
      window.history.back();
    } catch (err) {
      App.addNotification({ message: err.business || 'No se pudo eliminar el registro' });
    }
    setDisabled(false);
  };

  const onSave = async params => {
    setDisabled(true);
    if (!params) {
      params = await childRef.current.getParams();
      const invalidCounter = await childRef.current.validate(params);
      if (invalidCounter !== 0) {
        setDisabled(false);
        return;
      }
    }

    try {
      if (childRef.current.prePersist) params = await childRef.current.prePersist(params);

      if (_id) {
        await controller.update(dispatch, _id, params, { throwError: true });
      } else {
        const obj = await controller.create(params, { throwError: true });
        navigate(`/${model}/${obj._id}`, { replace: true });
      }
      App.addNotification({ message: 'Guardado exitosamente', variant: 'success' });
    } catch (err) {
      App.addNotification({ message: err.business || 'Hubo un error al guardar' });
    }

    setDisabled(false);
  };

  useEffect(() => {
    if (controller)
      if (_id) {
        controller.getOne(dispatch, _id, { messageError: 'Hubo un error al cargar el contenido' });
      } else {
        controller.cleanSelected(dispatch);
      }
  }, [_id, dispatch, controller]);

  useEffect(() => {
    if (childRef.current && childRef.current.isContainerFluid) {
      setIsContainerFluid(true);
    }
  }, [childRef]);

  useEffect(() => {
    if (state[model] && state[model].selected) {
      setSelected(state[model].selected);
      setDisabled(false);
    }
  }, [model, state]);

  useEffect(() => {
    if (childRef.current && childRef.current.controlButtons) {
      const controls = childRef.current.controlButtons(selected);
      setControlButtons(_.compact(controls.extra || []));
      setControlButtonsStd(controls.std);
    }
  }, [childRef, selected]);

  return (
    <Container className="app-element-page" fluid>

      <Modal show={viewDeleteUser} onHide={() => setViewDeleteUser(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{`¿Estás seguro de eliminar al usuario?.Esta acción no se puede deshacer.`}</p>
        </Modal.Body>
        <Modal.Footer>
          <DeleteUserPanel id={_id} setViewDeleteUser={setViewDeleteUser} />
        </Modal.Footer>
      </Modal>

      <Row className="panel">
        <Col xs="12" md={{ span: isContainerFluid ? 12 : 8, offset: isContainerFluid ? undefined : 2 }}>
          <Row>
            <Panel
              ref={childRef}
              {...{
                dispatch,
                state,
                selected,
                controller,
                permission,
                _id,
                onSave,
                onDelete
              }}
            />
          </Row>
        </Col>
      </Row>

      <Row className="button-control">
        <Col xs="12">
          {_.map(controlButtons || [], ({ title, action }, idx) => (
            <Button
              key={idx}
              variant="success"
              onClick={async () => {
                setDisabled(true);
                await childRef.current[action]();
                setDisabled(false);
              }}
              disabled={disabled}
            >
              {title}
            </Button>
          ))}
          {(!controlButtonsStd || controlButtonsStd.save) && permission.createOrUpdate(model, _id) && (
            <Button onClick={() => onSave()} disabled={disabled}>
              Guardar
            </Button>
          )}
          {canDelete && (!controlButtonsStd || controlButtonsStd.delete) && _id && permission.delete(model) && (
            <Button onClick={() => setViewDeleteUser(true)} variant="danger" disabled={disabled}>
              Eliminar
            </Button>
          )}
          <Button onClick={() => window.history.back()} variant="secondary" disabled={disabled}>
            Volver
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
