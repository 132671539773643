import React from 'react';

import MdRefresh from 'react-ionicons/lib/MdRefresh';

import { toast } from 'react-toastify';

import Auth from './Auth';

const model = 'app';

const ACTIONS = {
  ADD_ACTIONS: `${model}ADD_ACTIONS`
};

export const initialState = {
  [model]: {
    actions: undefined
  }
};

export const reducer = (state, { type, payload } = {}) => {
  if (type === ACTIONS.ADD_ACTIONS) {
    return {
      ...state,
      [model]: {
        ...state[model],
        actions: payload
      }
    };
  }

  return state;
};

const local = { dispatch: null };

export const setDispatch = dispatch => (local.dispatch = dispatch);

const addActions = actions => {
  return local.dispatch({
    type: ACTIONS.ADD_ACTIONS,
    payload: actions
  });
};

const addNotification = ({ message, variant = 'warning' }) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: variant
  });
};

let refToast = false;

const useAddSyncNotification = (count, _id) => {

  if (window.location.pathname === '/contents') {
    const contentToast = (count !== -1) ? <span>
      Hay {`${count} ${count === 1 ? 'contenido nuevo' : 'contenidos nuevos'}`}
      <MdRefresh color="white" beat={true} />
    </span> : <span>Hay más de 10 contenidos nuevos<MdRefresh color="white" beat={true} /></span>;
    if (!refToast) {
      refToast = toast.success(
        contentToast,
        {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          type: 'info',
          onClick: () => {
            localStorage.setItem('LAST_CONTENT_ID', _id);
            window.location.reload();
          },
          onClose: () => {
            localStorage.setItem('LAST_CONTENT_ID', _id);
          }
        }
      );
    } else {
      toast.update(refToast,
        {
          render: () =>contentToast,
          position: 'top-center',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          type: 'info',
          onClick: () => {
            localStorage.setItem('LAST_CONTENT_ID', _id);
            window.location.reload();
          },
          onClose: () => {
            localStorage.setItem('LAST_CONTENT_ID', _id);
          }
        })
    }
  }
};

const logout = () => {
  Auth.logout(local.dispatch);
};

export default {
  addActions,
  addNotification,
  useAddSyncNotification,
  logout
};
