import { useState, useEffect, useCallback } from 'react';

export const useAction = (action, ...params) => {
  const [state, _setState] = useState({});
  const setState = useCallback(newState => {
    _setState(prevState => ({ ...prevState, ...newState }));
  }, []);

  useEffect(() => {
    let didCancel = false;

    const callAction = async () => {
      setState({ loading: true, error: undefined });

      try {
        const data = await action(...params);
        if (!didCancel) setState({ data, error: undefined, loading: false });
      } catch (error) {
        if (!didCancel) setState({ error, data: undefined, loading: false });
      }
    };

    callAction();

    return () => {
      didCancel = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...params]);

  return state;
};

export default {
  useAction
};
