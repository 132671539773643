import _ from 'lodash';

const apiBase = '/v1/';

const has = role => {
  const result = {};
  result.list = (model, base = apiBase) => role && role.scopes && _.indexOf(role.scopes, `GET_${base}${model}`) >= 0;
  result.get = (model, base = apiBase) => role && role.scopes && _.indexOf(role.scopes, `GET_${base}${model}/`) >= 0;
  result.post = (model, base = apiBase) => role && role.scopes && _.indexOf(role.scopes, `POST_${base}${model}`) >= 0;
  result.put = (model, base = apiBase) => role && role.scopes && _.indexOf(role.scopes, `PUT_${base}${model}/`) >= 0;
  result.delete = (model, base = apiBase) =>
    role && role.scopes && _.indexOf(role.scopes, `DELETE_${base}${model}/`) >= 0;

  result.createOrUpdate = (model, id, base = apiBase) => (id ? result.put(model, base) : result.post(model, base));

  return result;
};

export default {
  has
};
