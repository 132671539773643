import _ from 'lodash';
import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import moment from 'moment';

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/es';

import { required } from '../../helper/Form';

import ReactTable from '../../components/ReactTable';
import InformService from '../../Services/Inform';

import services from './services';

const initial = now => {
  const start = now.minutes() === 0 ? now : now.startOf('hours');
  const end = start.clone().add(1, 'hour');

  return { startDate: start.toDate(), endDate: end.toDate(), contents: [] };
};

const columns = [
  {
    id: 'selection',
    text: '',
    dataField: (elm, data, selected) => {
      const exist = _.findIndex(selected, { id: elm._id }) >= 0;
      return <Form.Check type="checkbox" checked={exist} />;
    }
  },
  {
    text: 'Título',
    dataField: 'title'
  },
  {
    id: 'medium',
    text: 'Medio de Prensa',
    dataField: elm => (elm.medium && elm.medium.name) || '',
    sort: 'medium'
  },
  {
    text: 'Creado por',
    dataField: 'createdBy.name'
  },
  {
    text: 'Fecha y hora',
    dataField: 'dateTimeString'
  }
];

const isValidFunction = now => current => {
  return current.isAfter(now.clone().add(-48, 'hours')) && current.isBefore(now);
};

export default forwardRef(({ permission, dispatch, selected, _id }, ref) => {
  const now = useMemo(() => {
    const now = moment();
    return now.minutes() === 0 ? now : now.startOf('hours');
  }, []);
  const isValid = useMemo(() => isValidFunction(now), [now]);
  const [elm, setElement] = useState(initial(now));

  const [sort, setSort] = useState('-createdAt');
  const [contents, setContents] = useState([]);

  const evt = (field, key = 'value') => evt => setElement({ ...elm, [field]: evt.target[key] });
  const setter = data => setElement({ ...elm, ...data });

  useImperativeHandle(ref, () => ({
    onPublishAction: services.updateStatusToPublished(dispatch, _id),
    onUnpublishAction: services.updateStatusToUnpublish(dispatch, _id),

    controlButtons: services.controlButtons(permission, _id),

    validate: async inform => {
      let result = 0;
      result += required(inform.name, 'El nombre es requerido');
      return result;
    },

    getParams: async () => ({ ...elm })
  }));

  useEffect(() => {
    if (selected) {
      setElement({
        ...selected,
        startDate: new Date(selected.startDate),
        endDate: new Date(selected.endDate)
      });
    }
  }, [selected]);

  useEffect(() => {
    const exec = async () => {
      const list = await InformService.findAllContents(elm.startDate, elm.endDate, sort, _id);
      setContents(list);
    };
    exec();
  }, [elm.startDate, elm.endDate, sort, _id]);

  return (
    <>
      <Col xs="12">
        <Form.Label>Nombre</Form.Label>
        <Form.Control disabled={selected !== undefined} type="text" value={elm.name} onChange={evt('name')} />
      </Col>

      <Col xs="12" md="6">
        <Form.Label>Fecha y Hora Inicio</Form.Label>
        {elm.status === 'Publicado' ? (
          <Form.Control disabled={true} value={moment(elm.startDate).format('DD/MM/YYYY HH')} />
        ) : (
          <DateTime
            locale="es"
            value={elm.startDate}
            onChange={dateSelected =>
              dateSelected && dateSelected.toDate && setter({ startDate: dateSelected.toDate() })
            }
            strictParsing={true}
            closeOnTab={true}
            isValidDate={isValid}
            timeFormat="HH"
          />
        )}
      </Col>

      <Col xs="12" md="6">
        <Form.Label>Fecha y Hora Fin</Form.Label>
        {elm.status === 'Publicado' ? (
          <Form.Control disabled={true} value={moment(elm.endDate).format('DD/MM/YYYY HH')} />
        ) : (
          <DateTime
            disabled={elm.status === 'Publicado'}
            locale="es"
            value={elm.endDate}
            onChange={dateSelected => dateSelected && dateSelected.toDate && setter({ endDate: dateSelected.toDate() })}
            strictParsing={true}
            closeOnTab={true}
            isValidDate={isValid}
            timeFormat="HH"
          />
        )}
      </Col>

      {contents && contents.length > 0 && (
        <Col xs="12">
          <Form.Label>Contenidos</Form.Label>
          <ReactTable
            disabled={elm.status === 'Publicado'}
            columns={columns}
            data={contents}
            notPagination
            notEye
            multiSelectList={elm.contents}
            onRowSelected={({ _id, title, dateTime, dateTimeString }) => {
              const idx = _.findIndex(elm.contents, { id: _id });

              if (idx >= 0) {
                elm.contents.splice(idx, 1);
              } else {
                elm.contents.push({ id: _id, title, dateTime, dateTimeString });
              }
              setter({ contents: elm.contents });
            }}
            onSortChange={setSort}
          />
        </Col>
      )}
    </>
  );
});
