import _ from 'lodash';
import React, { useState, useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
const { MAX_SIZE_FILE } = require('../utils/Constants');


const thumbsContainer = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	marginTop: 16
};
  
const thumb = {
	display: "inline-flex",
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	marginRight: 8,
	width: "auto",
	height: 200,
	padding: 4,
	boxSizing: "border-box"
};

const thumbInner = {
	display: "flex",
	minWidth: 0,
	overflow: "hidden"
};

const img = {
	display: "block",
	width: "auto",
	height: "100%"
};

export default ({ disabled, accept, onChange, onCleanFile, value, forbiddenMsg }) => {
	const [file, setFile] = useState();
	const [_accept] = useState(accept);
	const [msg,setMsg] = useState("Arrastre o Agregue el archivo aquí...");
	const [filePreview, setFilePreview] = useState();
	const [uploadingFile, setUploadingFile] = useState(false);
	

	const cleanFile = () => {
		setFile();
		onCleanFile();
	};
	const checkExtensionFile = (fileName,accept) =>{
		const extension = fileName.split('.').pop();
		return _.includes(_.map(accept.split(','),(a)=> a.trim().split('/').pop()),extension)
	}

	const onDrop = useCallback(
		acceptedFiles => {
			const {type,name} = acceptedFiles[0]?acceptedFiles[0]:{type:undefined,name:undefined};
			if (!(_accept.includes(type)&& checkExtensionFile(name,accept))){
				setMsg(<span style={{color:"red"}}>{forbiddenMsg}</span>)
				return;
			}
			if(acceptedFiles[0].size > MAX_SIZE_FILE){
				setMsg(<span style={{color:"red"}}>El archivo supera el máximo permitido (40MB).</span>)
				return;
			}
			setMsg("Arrastre o Agregue el archivo aquí...");
			
			setUploadingFile(true);
			onChange(acceptedFiles[0]);
			setFile(
				Object.assign(acceptedFiles[0], {
					preview: URL.createObjectURL(acceptedFiles[0])
				})
			);

			let reader = new FileReader();

			reader.onload = function (e) {
				setFilePreview(e.target.result);
			};
			reader.readAsDataURL(acceptedFiles[0]);
			setUploadingFile(false);
			
		},
		[onChange,_accept,accept,forbiddenMsg]
	);

	const thumbs = (file)?(
		<div style={thumb} key={file.name}>
		  <div style={thumbInner}>
			<img alt="preview" src={file.preview} style={img} />
		  </div>
		</div>
	  ): undefined;
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept, multiple: false, onDrop });

	return (
		<>
			{uploadingFile && (
				<div className="img-loader-container">
					<div className="img-loader"></div>
					<div className="img-loader-text">
						<span>Subiendo...</span>
					</div>
				</div>
			)}
			{!uploadingFile && disabled && <div className="dropzone"></div>}
			{!uploadingFile && !disabled && !value && !file && (
				<div {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} />
					{isDragActive ? <p>Suelte el archivo aquí...</p> : <p>{msg}</p>}
				</div>
			)}
			{!uploadingFile && (value || file) && (
				<div className="dropzone-file">
					{accept === 'image/*' && <img src={value || filePreview} alt="preview" />}
					{!disabled && <small onClick={cleanFile}>[borrar]</small>}
					<aside style={thumbsContainer}>{thumbs}</aside>
				</div>
			)}
		</>
	);
};
