import React from 'react';

import * as Auth from './Auth';

import * as Contents from './Contents';
import * as Tags from './Tags';
import * as Users from './Users';
import * as Roles from './Roles';
import * as Scopes from './Scopes';
import * as Medium from './Medium';
import * as Inform from './Inform';
import * as Audit from './Audit';
import * as Versions from './Versions';

import * as App from './App';

export const Store = React.createContext();

const initialState = {
  ...Auth.initialState,
  ...Contents.initialState,
  ...Tags.initialState,
  ...Users.initialState,
  ...Roles.initialState,
  ...Scopes.initialState,
  ...Medium.initialState,
  ...Inform.initialState,
  ...Audit.initialState,
  ...Versions.initialState,

  ...App.initialState
};

const reducerList = [
  Auth.reducer,
  Contents.reducer,
  Tags.reducer,
  Users.reducer,
  Roles.reducer,
  Scopes.reducer,
  Medium.reducer,
  Inform.reducer,
  Audit.reducer,
  Versions.reducer,

  App.reducer
];

function reducer(state, action) {
  let stateResult = state;
  reducerList.forEach(reducer => {
    stateResult = reducer(stateResult, action);
  });
  return stateResult;
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
