import React from 'react';
import { Form, Button } from 'react-bootstrap';

import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

export default ({ content, setter, disabled, data, setSearch }) => {

  return (
    <>
      <Form.Check
        disabled={disabled}
        type="checkbox"
        label="es Sub-Contenido"
        checked={content.parent !== undefined}
        onChange={evt => setter({ parent: evt.target.checked ? {} : undefined })}
      />
      {content.parent !== undefined && (
        <>
          <DropdownList
            disabled={disabled}
            filter={() => true}
            value={content.parent}
            data={data}
            textField={item => (item && item.title ? `${item.title} - ${item.dateTimeString}` : '')}
            onSelect={({ _id, title, dateTimeString, profile, destinations, tags, medium }) =>{
              console.log(profile);
              setter({
                parent: { title, id: _id, dateTimeString, profileId: profile },
                destinations,
                profile: profile,
                tags,
                medium
              })
            }
            }
            onSearch={setSearch}
            messages={{
              emptyList: 'sin contenidos'
            }}
          />
          {content._id && content.parent && content.parent.id && (
            <Button variant="link" href={`/contents/${content.parent.id}`}>
              Ir al Contenido Enlazado
            </Button>
          )}
        </>
      )}
    </>
  );
};
