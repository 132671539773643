import contents from './Services/Contents';
import tags from './Services/Tags';
import users from './Services/Users';
import roles from './Services/Roles';
import medium from './Services/Medium';
import inform from './Services/Inform';
import audit from './Services/Audit';
import versions from './Services/Versions';

const controllers = { contents, tags, users, roles, medium, inform, audit, versions };

export default model => controllers[model];
