import _ from 'lodash';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Form } from 'react-bootstrap';

import Multiselect from 'react-widgets/lib/Multiselect';
import 'react-widgets/dist/css/react-widgets.css';

import Scopes from '../../Services/Scopes';
import Roles from '../../Services/Roles';
import { required } from '../../helper/Form';

const initial = { scopes: [], presets: [] };

const checkPresetsSelected = (role, _presets) => {
  const presets = _.cloneDeep(_presets);

  _.each((role || {}).scopes, scope => {
    _.each(presets, preset => {
      const idx = _.indexOf(preset, scope);
      if (idx >= 0) {
        preset.splice(idx, 1);
      }
    });
  });

  if (!presets.admin || presets.admin.length === 0) return ['admin'];

  return _.reduce(
    presets,
    (result, preset, name) => {
      if (!preset || preset.length === 0) {
        result.push(name);
      }
      return result;
    },
    []
  );
};

const addPreset = (actualScope, addScope, presets) => {
  if (_.indexOf(presets, 'admin') >= 0) return actualScope;
  return _.uniq([...actualScope, ...addScope]);
};

const remPreset = (actualScope, remScope, presets) => {
  if (_.indexOf(presets, 'admin') >= 0) return actualScope;
  return _.filter(actualScope, scope => _.indexOf(remScope, scope) < 0);
};

export default forwardRef(({ selected, dispatch, state }, ref) => {
  const [elm, setElement] = useState(initial);
  // const [scopes, setScopes] = useState();
  const [presets, setPresets] = useState();

  const evt = (field, key = 'value') => evt => setElement({ ...elm, [field]: evt.target[key] });
  const setter = data => setElement(prevElm => ({ ...prevElm, ...data }));

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let result = 0;
      result += required(elm.name, 'El Nombre es requerido');
      return result;
    },

    getParams: async () => {
      return elm;
    }
  }));

  useEffect(() => {
    if (selected) {
      if (state.roles.presets && !_.isEmpty(state.roles.presets)) {
        selected.presets = checkPresetsSelected(selected, state.roles.presets);
      }

      setElement(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (dispatch && (!state.roles.presets || !Object.keys(state.roles.presets).length)) {
      Roles.getPresets(dispatch);
    }
  }, [dispatch, state.roles.presets]);

  useEffect(() => {
    if (dispatch && !state.scopes.list) {
      Scopes.findAll(dispatch, { pagination: false });
    }
  }, [dispatch, state.scopes.list]);

  // useEffect(() => {
  //   if (state.scopes.list && state.scopes.list.length > 0) {
  //     const _scopes = {};

  //     _.each(state.scopes.list, scope => {
  //       if (!scope.parent) return;
  //       if (!_scopes[scope.parent]) _scopes[scope.parent] = [];
  //       _scopes[scope.parent].push(scope.name);
  //     });

  //     setScopes(_scopes);
  //   }
  // }, [state.scopes.list]);

  useEffect(() => {
    if (state.roles.presets && !_.isEmpty(state.roles.presets)) {
      const _presets = Object.keys(state.roles.presets);
      const _presetsSelected = checkPresetsSelected(elm, state.roles.presets);

      setPresets(_presets);
      setter({ presets: _presetsSelected });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.roles.presets]);

  return (
    <>
      <Col xs="12">
        <Form.Label>Nombre</Form.Label>
        <Form.Control disabled={selected !== undefined} type="text" value={elm.name} onChange={evt('name')} />
      </Col>

      {presets && (
        <Col xs="12">
          <Form.Label>Valores Pre-Seteados</Form.Label>
          <Multiselect
            value={elm.presets}
            data={presets}
            onChange={(presets, { action, dataItem }) => {
              const scopes = (action === 'insert' ? addPreset : remPreset)(
                elm.scopes,
                state.roles.presets[dataItem],
                presets
              );
              setter({ presets, scopes });
            }}
          />
        </Col>
      )}

      {/* {scopes &&
        _.map(scopes, (scopeList, parent) => {
          return (
            <Col xs="12" key={parent}>
              <Form.Label>{parent}</Form.Label>
              {_.map(scopeList, scope => {
                return (
                  <Form.Check
                    key={`${parent}_${scope}`}
                    type="checkbox"
                    label={scope}
                    checked={_.indexOf(elm.scopes, scope) >= 0}
                    onChange={() => {
                      const scopes = [...elm.scopes];
                      if (_.indexOf(scopes, scope) >= 0) {
                        _.remove(scopes, s => s === scope);
                      } else {
                        scopes.push(scope);
                      }
                      setElement({ ...elm, scopes });
                    }}
                  />
                );
              })}
            </Col>
          );
        })} */}
    </>
  );
});
