import React from 'react';

import ListPage from '../../components/admin/ListPage';
import ElementPage from '../../components/admin/ElementPage';
import VersionsPanel from './VersionsPanel';

const model = 'versions';

const screenMapper = valBool => (valBool ? 'SI' : 'NO');

const columns = [
  {
    id: 'active',
    text: 'Actual',
    dataField: ({ active }) => screenMapper(active)
  },
  {
    text: '#',
    dataField: 'versionNumber'
  },
  {
    id: 'Mandatory',
    text: 'Obligatorio',
    dataField: ({ isMandatory }) => screenMapper(isMandatory)
  },
  {
    id: 'hasPN',
    text: 'Tiene PN',
    dataField: ({ hasPN }) => screenMapper(hasPN)
  }
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} path={`/${model}`} />, // LIST
  <ElementPage key={`${model}_create`} model={model} Panel={VersionsPanel} path={`/${model}/create`} canDelete />, // CREATE
  <ElementPage key={`${model}_edit`} model={model} Panel={VersionsPanel} path={`/${model}/:_id`} canDelete /> // EDIT
];
