import { createCRUD } from './utils';
import App from './App';
import * as Api from '../Api';

const model = 'users';

const ACTIONS = {
  FIND_ALL: `${model}FIND_ALL`,
  GET: `${model}GET`
};

export const initialState = {
  [model]: {
    list: undefined,
    total: undefined,
    selected: undefined
  }
};

export const reducer = (state, { type, payload } = {}) => {
  if (type === ACTIONS.FIND_ALL) {
    return {
      ...state,
      [model]: {
        ...state[model],
        list: payload.list,
        total: payload.total
      }
    };
  }

  if (type === ACTIONS.GET) {
    return {
      ...state,
      [model]: {
        ...state[model],
        selected: payload
      }
    };
  }

  return state;
};

const { findAll, getOne, cleanSelected, create, update } = createCRUD(model, ACTIONS);

const remove = async _id => {
  try {
    const res = await Api.privateDelete(`v1/users/${_id}`, { _id });
    App.addNotification({ message: res, variant: 'success' });
    window.history.back();
    return res;
  } catch (err) {
    App.addNotification({ message: err.business || err.message });
    throw err;
  }
};

export default {
  findAll,
  getOne,
  cleanSelected,
  create,
  remove,
  update
};
