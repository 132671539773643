import React, { useState } from 'react';
import _ from 'lodash';
import { css } from 'emotion';

import { Row, Col, Form, Button, Modal } from 'react-bootstrap';

import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import { useAction } from '../../../helper/Hooks';
import Actions from './actions';

const buttonsCss = css`
  button {
    margin: 1rem;
  }
`;

const ModalParent = ({ actionFn, onClose, content }) => {
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState();
  const { data } = useAction(Actions.findParents, search, content);

  return (
    <div>
      <Col xs="12">Seleccione un nuevo Padre</Col>

      <Col xs="12">
        <DropdownList
          filter={() => true}
          value={selected}
          data={data}
          textField={item => (item && item.title ? `${item.title} - ${item.dateTimeString}` : '')}
          onSelect={({ _id, title, dateTimeString, destinations, tags, medium }) =>
            setSelected({ title, id: _id, dateTimeString })
          }
          onSearch={setSearch}
          messages={{
            emptyList: 'sin contenidos'
          }}
        />
      </Col>

      <Col xs="12" className={buttonsCss}>
        <Button disabled={!selected} onClick={actionFn('changeParent', selected && selected.id)} block>
          Seleccionar
        </Button>

        <Button variant="secondary" onClick={onClose} block>
          Cancelar
        </Button>
      </Col>
    </div>
  );
};

export default ({ content, setLoading, onClose, deleteContent }) => {
  const [modal, setModal] = useState();

  const actionFn = (action, newParent) => async () => {
    setLoading(true);
    await deleteContent(content, action, newParent);
    onClose();
    setLoading(false);
  };

  return (
    <div>
      <Modal show={modal} onHide={() => setModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar Nuevo Padre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalParent {...{ actionFn, content, onClose: () => setModal() }} />
        </Modal.Body>
      </Modal>

      <Col xs="12">
        <strong>Este contenido tiene hijos, que desea hacer con ellos?</strong>
      </Col>

      <Col xs="12">
        <Form.Label>Hijos</Form.Label>
        <Row>
          {_.map(content.hasChildren.list, child => {
            return (
              <Col xs="12" key={child._id}>
                <Button variant="link" href={`/contents/${child._id}`} target="_blank">
                  {child.title} - {child.dateTimeString}
                </Button>
              </Col>
            );
          })}
        </Row>
      </Col>

      <Col xs="12" className={buttonsCss}>
        <Button onClick={actionFn('deleteAll')} block>
          Borrar Padre e Hijos
        </Button>

        <Button onClick={actionFn('childIndependent')} block>
          Borrar Padre y crear Hijos independientes
        </Button>

        <Button onClick={() => setModal(true)} block>
          Borrar Padre y asociar a un nuevo Padre a estos Hijos
        </Button>

        <Button variant="secondary" onClick={onClose} block>
          Cancelar
        </Button>
      </Col>
    </div>
  );
};
