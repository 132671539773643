import _ from 'lodash';
import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';

export const destinationList = {
  sep: 'SEPyME',
  sci: 'SCI',
  sm: 'SM',
  sie: 'SIECyGCE',
  sga: 'SGA',
  uga: 'UGA'
};

const destinations = [
  destinationList.sep,
  destinationList.sci,
  destinationList.sm,
  destinationList.sie,
  destinationList.sga,
  destinationList.uga
];

const destinationEnableList = {};

const verifyEnable = (value, destination) => {
  if (value.length === 0 || _.indexOf(value, destination) >= 0) return true;
  if (!destinationEnableList[destination]) return false;
  return _.isEqual(value, destinationEnableList[destination]);
};

export default ({ disabled, value = [], onChange }) => {
  return (
    <Row>
      <Col xs="12">
        {_.map(destinations, destination => {
          const destinationKey = destination;

          return (
            <Form.Group key={destinationKey}>
              <Form.Check
                disabled={disabled || !verifyEnable(value, destinationKey)}
                type="checkbox"
                label={destination}
                checked={_.indexOf(value, destinationKey) >= 0}
                onChange={evt => {
                  if (evt.target.checked) {
                    onChange([...value, destinationKey]);
                  } else {
                    onChange(value.filter(dest => dest !== destinationKey));
                  }
                }}
              />
            </Form.Group>
          );
        })}
      </Col>
    </Row>
  );
};
