import _ from 'lodash';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Form } from 'react-bootstrap';

import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import { required } from '../../helper/Form';

const statusList = ['Activo', 'Normalizado', 'Inactivo'];

const initial = {
  status: statusList[0]
};

export default forwardRef(({ selected, controller, dispatch, state, _id }, ref) => {
  const [elm, setElement] = useState(initial);
  const [search, setSearch] = useState();

  const evt = (field, key = 'value') => evt => {
    const value = evt.target[key];
    setElement(prev => {
      return { ...prev, [field]: value };
    });
  };

  const changeStatus = evt => {
    const status = evt.target.value;
    setElement(prev => ({
      ...prev,
      status,
      normalized: undefined
    }));
  };

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let result = 0;
      result += required(elm.name, 'El nombre es requerido');
      return result;
    },

    getParams: async () => {
      return elm;
    }
  }));

  useEffect(() => {
    if (selected) {
      setElement(selected);
    }
  }, [selected]);

  /**
   * CUSTOMS DATA
   */

  useEffect(() => {
    if (controller && elm.status === statusList[1])
      controller.findAll(
        dispatch,
        { limit: 25, search, exclude: _id, filter: { status: 'Activo' } },
        { messageError: 'Hubo un error al cargar los tags' }
      );
  }, [controller, dispatch, search, elm.status, _id]);

  return (
    <>
      <Col xs="12">
        <Form.Label>Nombre</Form.Label>
        <Form.Control disabled={_id !== undefined} type="text" value={elm.name} onChange={evt('name')} />
      </Col>

      {_id && selected && (
        <>
          <Col xs="12">
            <Form.Label>Estado</Form.Label>
            <Form.Control as="select" value={elm.status} onChange={changeStatus}>
              {_.map(statusList, status => (
                <option key={status}>{status}</option>
              ))}
            </Form.Control>
          </Col>

          <Col xs="12">
            <Form.Label>Normalizar con</Form.Label>
            <DropdownList
              disabled={elm.status !== statusList[1]}
              filter={() => true}
              value={elm.normalized}
              data={state.tags.list}
              textField="name"
              onSelect={({ _id, name }) => setElement(prev => ({ ...prev, normalized: { name, id: _id } }))}
              onSearch={setSearch}
              messages={{
                emptyList: 'sin tags'
              }}
            />
          </Col>
        </>
      )}
    </>
  );
});
