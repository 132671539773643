import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Api from './Api';
import Auth from './Services/Auth';
import App from './Services/App';
import { Store } from './Services';
import { setDispatch } from './Services/App';

import ReactIonicons from './components/ReactIonicons';
import packageJson from '../package.json';

import header from './img/logo_infoprod_white.png';

import Permission from './helper/Permission';

const { version } = packageJson;

let interval;

export default props => {
  const { dispatch, state } = React.useContext(Store);

  //const [theme] = useState(localStorage.getItem('theme') || 'cerulean');

  // const changeTheme = () => {
  //   if (theme === 'cerulean') {
  //     localStorage.setItem('theme', 'superhero');
  //   } else {
  //     localStorage.setItem('theme', 'cerulean');
  //   }
  //   window.location.reload();
  // };

  const handleBackButton = async () => {
    window.history.back();
  };


  useEffect(() => {
    setDispatch(dispatch);
    Auth.syncLoggedIn(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const syncAudit = async () => {
      const res = await Api.private.get('contents', { sort: '-createdAt', limit: 10 });
      const { list } = res;
      const lastContentId = localStorage.getItem('LAST_CONTENT_ID');
      if (!lastContentId) {
        const { _id } = list[0];
        localStorage.setItem('LAST_CONTENT_ID', _id);
        return;
      }
      const index = list.findIndex((item) => item._id === lastContentId);
      if (index !== 0) {
        const { createdBy } = list[0];
        const { _id } = list[0];
        if (createdBy.id !== state.user._id) {
          App.useAddSyncNotification(index, _id);
        }
      }
    };

    if (state.role) {
      const permission = Permission.has(state.role);
      const isEditor = permission.put('contents/status');

      if (isEditor && !interval) {
        interval = setInterval(syncAudit, 10 * 1000);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [state.role]);

  return (
    <>
      <header className="header">
        <Navbar collapseOnSelect expand="lg" bg="primary" variant="light" fixed="top">
          {props.location.pathname === '/' ? (
            <Navbar.Brand className="back-button-empty">
              <span />
            </Navbar.Brand>
          ) : (
            <Navbar.Brand className="back-button" onClick={handleBackButton}>
              <ReactIonicons logo="chevron-back-outline" />
            </Navbar.Brand>
          )}
          <Navbar.Brand href="/">
            <img className="logo" src={header} alt="header" />
          </Navbar.Brand>
          <Nav className="mr-auto" />
          <Nav>
            <NavDropdown alignRight title={<img alt="usuario" style={{ height: '50px' }} src="./assets/icons/people-circle-outline.svg" />} id="collasible-nav-dropdown">
              {state.user && (
                <NavDropdown.Item disabled>
                  <small>{state.user.username}</small>
                </NavDropdown.Item>
              )}
              {/* <NavDropdown.Item onClick={changeTheme}>
                {theme === 'cerulean' ? 'Modo Noche' : 'Modo Día'}
              </NavDropdown.Item> */}
              <NavDropdown.Item onClick={() => Auth.logout(dispatch)}>Salir</NavDropdown.Item>
              <NavDropdown.Item disabled>
                <small>version {version}</small>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar>

      </header>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {state.role && props.children}
    </>
  );
};
