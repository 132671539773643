import App from './App';

import * as Api from '../Api';

export const createCRUD = (model, ACTIONS) => {
  const findAll = async (
    dispatch,
    { search, page, limit, sort, exclude, pagination, filter } = {},
    { messageError, cbError, cb, throwError } = {}
  ) => {
    try {
      const res = await Api.findAll(model, { search, page, limit, sort, exclude, pagination, filter });

      dispatch &&
        dispatch({
          type: ACTIONS.FIND_ALL,
          payload: res
        });
      cb && cb(res);
      return res;
    } catch (err) {
      if (throwError) throw err;
      cbError ? cbError(err) : App.addNotification({ message: messageError || err.business || err.message });
    }
  };

  const getOne = async (dispatch, id, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.getOne(model, id);
      dispatch({
        type: ACTIONS.GET,
        payload: res.data
      });
      cb && cb(res);
      return res;
    } catch (err) {
      if (throwError) throw err;
      cbError ? cbError(err) : App.addNotification({ message: messageError || err.business || err.message });
    }
  };

  const cleanSelected = async dispatch => {
    return dispatch({
      type: ACTIONS.GET,
      payload: undefined
    });
  };

  const create = async (params, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.create(model, params);
      cb && cb(res);
      return res;
    } catch (err) {
      if (throwError) throw err;
      cbError ? cbError(err) : App.addNotification({ message: messageError || err.business || err.message });
    }
  };

  const update = async (dispatch, id, params, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.update(model, id, params);
      dispatch({
        type: ACTIONS.GET,
        payload: res.data
      });
      cb && cb(res);
      return res;
    } catch (err) {
      if (throwError) throw err;
      cbError ? cbError(err) : App.addNotification({ message: messageError || err.business || err.message });
    }
  };

  const updateStatus = async (dispatch, id, params, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.update(`${model}/status`, id, params);
      dispatch({
        type: ACTIONS.GET,
        payload: res.data
      });
      cb && cb(res);
      return res;
    } catch (err) {
      if (throwError) throw err;
      cbError ? cbError(err) : App.addNotification({ message: messageError || err.business || err.message });
    }
  };

  const remove = async (id, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.remove(model, id);
      cb && cb(res);
      return res.data;
    } catch (err) {
      if (throwError) throw err;
      cbError ? cbError(err) : App.addNotification({ message: messageError || err.business || err.message });
    }
  };

  return {
    findAll,
    getOne,
    create,
    update,
    updateStatus,
    remove,
    cleanSelected
  };
};
