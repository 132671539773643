import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Card, CardColumns } from 'react-bootstrap';

import { Store } from '../../Services';
import Permission from '../../helper/Permission';

import pages from './pages';
import './home.css';
export default () => {
  const { state } = useContext(Store);

  const [menu, setMenu] = useState();

  useEffect(() => {
    if (state.role) {
      const permission = Permission.has(state.role);
      const _menu = [];
      _.each(pages, page => {
        if (permission.list(page.scope)) {
          _menu.push(page);
        }
      });

      setMenu(_menu);
    }
  }, [state.role]);

  return (
    <>
      <Container style={{ maxWidth: '1330px',position:'relative',top:'calc(24vh - 169px)'}}>
        <Row style={{ borderBottom: '2px solid #e0e0e0', margin: '3em 0', paddingTop: '8vh'}}>
          <h2 className='title'>¡Bienvenido/a!</h2>
        </Row>
        <Row className="justify-content-md-center cards-container">
          {menu &&
            _.map(menu, (page, idx) => { 
              return(
              <a href={page.goTo} className='nostyle'>
                <Card className="card-item">
                  <Card.Title className='card-title'>  {page.icon && <img alt={`botón ${page.icon}`} className='icon-btn' src={`./assets/icons/${page.icon}.svg`} />}  </Card.Title>
                  <Card.Body  className='card-body-home'>
                    <Card.Text className='text-center'>
                      <h5 style={{ color: '#000', fontWeight: 'bold' }}>{page.name} </h5>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
              
            )})}
        </Row>
      </Container>



      <footer className='footer-home'>
        <img alt="logo ministerio" className="justify-content-end" style={{height:'45px',margin:'18px'}} src="./assets/img/EscudoFooter.png" ></img>
      </footer>
    </>
  );
};
