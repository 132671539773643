import React from 'react';

import ListPage from '../../components/admin/ListPage';
import ElementPage from '../../components/admin/ElementPage';
import RolesPanel from './RolesPanel';

const model = 'roles';

const columns = [
  {
    text: 'Nombre',
    dataField: 'name'
  }
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} path={`/${model}`} />, // LIST
  <ElementPage key={`${model}_create`} model={model} Panel={RolesPanel} path={`/${model}/create`} />, // CREATE
  <ElementPage key={`${model}_edit`} model={model} Panel={RolesPanel} path={`/${model}/:_id`} /> // EDIT
];
