import React from 'react';

import ListPage from '../../components/admin/ListPage';
import ElementPage from './ElementPage';
import UsersPanel from './UsersPanel';
import UsersPanelEdit from './UsersPanelEdit';

const model = 'users';

const statusList = {
  '': 'Todos los estados',
  Pendiente: 'Pendiente',
  PendienteValidar: 'Pendiente Validación',
  Activo: 'Activo',
  Inactivo: 'Inactivo'
};

const columns = [
  {
    id: 'firstName',
    text: 'Nombre',
    sort: 'firstName',
    dataField: elm => `${elm.firstName}, ${elm.lastName}`
  },
  {
    text: 'Rol',
    dataField: 'role'
  },
  {
    text: 'Perfil',
    dataField: 'profileName'
  },
  {
    text: 'Estado',
    dataField: 'status'
  },
  {
    text: 'Usuario',
    dataField: 'username'
  },
  {
    text: 'Email',
    dataField: 'email'
  },
  
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} statusList={statusList} path={`/${model}`} />, // LIST
  <ElementPage key={`${model}_create`} model={model} Panel={UsersPanel} path={`/${model}/create`} />, // CREATE
  <ElementPage key={`${model}_edit`}  canDelete={true} model={model} Panel={UsersPanelEdit} path={`/${model}/:_id`} /> // EDIT
];
