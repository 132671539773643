import React from 'react';
import moment from 'moment';

import ListPage from '../../components/admin/ListPage';

const model = 'audit';

const columns = [
  {
    id: 'id',
    text: 'Id',
    dataField: elm => elm.externalID
  },
  {
    id: 'title',
    text: 'Título',
    dataField: elm => elm.data.name || elm.data.title
  },
  {
    id: 'destination',
    text: 'Destino',
    dataField: elm => (elm.data.destinations ? elm.data.destinations[0] : 'Resumen')
  },
  {
    id: 'user',
    text: 'Usuario',
    dataField: elm => `${elm.user.firstName}, ${elm.user.lastName}`
  },
  {
    id: 'role',
    text: 'Rol',
    dataField: elm => elm.user.role
  },
  {
    id: 'createdAt',
    text: 'Fecha/Hora',
    dataField: elm => moment(elm.createdAt).format('DD/MM/YYYY HH:mm')
  },
  {
    id: 'status',
    text: 'Estado',
    dataField: elm => (elm.action === 'Create' ? 'Creado' : elm.data.status)
  }
];

export default [
  <ListPage
    key={`${model}_list`}
    model={model}
    columns={columns}
    path={`/${model}`}
    fluid={true}
    notSelectable
    notSearch
  /> // LIST
];
