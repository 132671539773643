import _ from 'lodash';

import App from '../Services/App';

export const link = (value, message) => {
  if (!value) {
    return 0;
  }
  const regex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

  if (value.substring(0, 7) !== 'http://' && value.substring(0, 8) !== 'https://') {
    App.addNotification({
      message
    });
    return 1;
  }

  if (value.substring(0, 7) === 'http://') {
    const cutUrl = value.substring(7, value.length);
    if (cutUrl.search('/') !== -1) {
      if (!regex.test('http://' + cutUrl.substring(0, cutUrl.search('/')))) {
        App.addNotification({
          message
        });
        return 1;
      }
    } else {
      if (!regex.test('http://' + cutUrl)) {
        App.addNotification({
          message
        });
        return 1;
      }
    }
  }

  if (value.substring(0, 8) === 'https://') {
    const cutUrl = value.substring(8, value.length);
    console.log(cutUrl);
    if (cutUrl.search('/') !== -1) {
      if (!regex.test('https://' + cutUrl.substring(0, cutUrl.search('/')))) {
        App.addNotification({
          message
        });
        return 1;
      }
    } else {
      if (!regex.test('https://' + cutUrl)) {
        App.addNotification({
          message
        });
        return 1;
      }
    }
  }
  return 0;
};

export const required = (value, message) => {
  if (value === undefined || value === null || value === '' || (_.isArray(value) && value.length === 0)) {
    App.addNotification({
      message
    });
    return 1;
  }
  return 0;
};

export const min = (value, limit, message) => {
  if (!value || value.length < limit) {
    App.addNotification({
      message
    });
    return 1;
  }
  return 0;
};

export const password = value => {
  if (!/^(?=.*[\u0021-\u002b\u003c-\u0040])\S{6,16}$/.test(value)) {
    App.addNotification({
      message:
        'La contraseña debe estar compuesta por 6 caracteres como mínimo y debe contener al menos un caracter especial.(Ejemplos de caracteres especiales: *"#$%&()=?)'
    });
    return 1;
  }
  return 0;
};

export const email = value => {
  const exp =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

  if (!exp.test(value)) {
    App.addNotification({
      message: 'El Email no es válido.Debe tener el formato ejemplo@ejemplo.com'
    });
    return 1;
  }
  return 0;
};

export const phone = value => {
  if (value && value.length < 10) {
    App.addNotification({
      message:
        'El Teléfono celular no es válido. Debe estar compuesto por 10 digitos y el formato es [código de area] + [número de telefono (sin prefijo 15)]. Ej:11 1234-1234'
    });
    return 1;
  }
  return 0;
};

export const validVersion = value => {
  const exp = /^(\d+).(\d+).(\d+)(-.*)?/;

  if (!exp.test(value)) {
    App.addNotification({
      message: 'La versión debe tener el formato x.y.z'
    });
    return 1;
  }
  return 0;
};
