import InformService from '../../Services/Inform';
import AppService from '../../Services/App';

const getIsEditor = permission => {
  return permission.put('inform/status');
};

const getParams = async inform => ({
  ...inform
});

const updateStatus = async (dispatch, _id, data, message, messageError) => {
  try {
    await InformService.updateStatus(dispatch, _id, data, { throwError: true });
    AppService.addNotification({ message, variant: 'success' });
  } catch (err) {
    AppService.addNotification({ message: err.business || messageError });
  }
};

const updateStatusToPublished = (dispatch, _id) => async () => {
  return updateStatus(dispatch, _id, { status: 'Publicado' }, 'Resumen Publicado!', 'No se pudo publicar este resumen');
};

const updateStatusToUnpublish = (dispatch, _id) => async () => {
  return updateStatus(
    dispatch,
    _id,
    { status: 'Despublicado' },
    'Resumen Despublicado!',
    'No se pudo despublicar este resumen'
  );
};

const controlButtons = (permission, _id, hasChildren) => selected => {
  const _isEditor = getIsEditor(permission);
  const status = selected ? selected.status : 'Pendiente';

  return {
    extra: [
      (status === 'Pendiente' || status === 'Despublicado') &&
        _id &&
        _isEditor && {
          title: 'Publicar',
          action: 'onPublishAction'
        },
      status === 'Publicado' &&
        _isEditor && {
          title: 'Despublicar',
          action: 'onUnpublishAction'
        }
    ],
    std: { save: true, delete: status === 'Pendiente' }
  };
};

export default {
  getIsEditor,
  getParams,
  controlButtons,
  updateStatusToPublished,
  updateStatusToUnpublish
};
