import React from 'react';

import ListPage from '../../components/admin/ListPage';

import ContentsPanel from './ContentsPanel';

const model = 'contents';

const statusList = {
  '': 'Todos los estados',
  Pendiente: 'Pendiente',
  Revision: 'Revisión',
  Publicado: 'Publicado',
  Rechazado: 'Rechazado',
  Despublicado: 'Despublicado',
  Rehabilitacion: 'Rehabilitación'
};

const columns = [
  {
    text: 'Título',
    dataField: 'title'
  },
  {
    id: 'destination',
    text: 'Origen información',
    dataField: elm =>
      elm.destinations && elm.destinations.length > 0
        ? elm.destinations[0] === 'Informes'
          ? 'Resúmen'
          : elm.destinations[0]
        : '-',
    sort: 'destinations'
  },
  {
    text: 'Perfil',
    dataField: 'profileName'
  },
  {
    text: 'Creado por',
    dataField: 'createdBy.name'
  },
  {
    text: 'Fecha',
    dataField: 'dateTimeString',
    sort: 'dateTime'
  },
  {
    id: 'status',
    text: 'Estado',
    dataField: elm => statusList[elm.status],
    sort: 'status'
  },
  {
    id: 'parent',
    text: 'Contenido Enlazado',
    dataField: elm => (elm.parent ? elm.parent.title : ''),
    sort: 'parent'
  }
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} statusList={statusList} path={`/${model}`} fluid />, // LIST
  <ContentsPanel key={`${model}_create`} path={`/${model}/create`} />, // CREATE
  <ContentsPanel key={`${model}_edit`} path={`/${model}/:_id`} /> // EDIT
];
