import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/es';

import RichEditor from '../../../../components/RichEditor/RichEditor';
import FileUpload from '../../../../components/FileUpload';
import Destinations, { destinationList } from '../../../../components/Destinations';
import ProfilesCheck from '../../../../components/ProfilesCheck';
import Assessment from '../../../../components/Assessment';
import CharacterCounter from './CharacterCounter';

import Tags from './Tags';
import Parent from './Parent';

import Actions from '../actions';

const statusList = {
  '': 'Todos los estados',
  Pendiente: 'Pendiente',
  Revision: 'Revisión',
  Publicado: 'Publicado',
  Rechazado: 'Rechazado',
  Despublicado: 'Despublicado',
  Rehabilitacion: 'Rehabilitación a Revisión'
};

const componentsVisible = {
  Always: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ],
  [destinationList.sm]: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ],
  [destinationList.sep]: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ],
  [destinationList.sci]: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ],
  [destinationList.sie]: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ],
  [destinationList.sga]: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ],
  [destinationList.uga]: [
    { component: 'status' },
    { component: 'acceptChildren', role: 'editor' },
    { component: 'tags', role: 'editor' },
    { component: 'destinations' },
    { component: 'profiles' },
    { component: 'dateTime', props: { timeFormat: true, dateFormat: true } },
    { component: 'medium' },
    { component: 'assessment' },
    { component: 'hasPN', role: 'editor' },
    { component: 'imageFileName' },
    { component: 'attachFileName' },
    { component: 'title' },
    { component: 'parent' },
    { component: 'children' },
    { component: 'summary' },
    { component: 'text' },
    { component: 'sourceLink' }
  ]
};

const VisibleTo =
  ([destination = 'Always'] = [], role) =>
  name => {
    const elm = _.find(componentsVisible[destination], { component: name });

    if (!elm || (elm.role && elm.role !== role)) return false;

    return true;
  };

const PropsTo =
  ([destination = 'Always'] = []) =>
  name => {
    const elm = _.find(componentsVisible[destination], { component: name });

    if (!elm) return {};
    elm.props.timeFormat = false;

    return elm.props ? elm.props : {};
  };

export default ({ content, setter, role, disabled }) => {
  useEffect(() => {
    const exec = async () => {
      const result = await Actions.getChildren(content._id);
      setter({ hasChildren: result }, true);
    };

    if (content._id && !content.parent) exec();
  }, [content._id, content.parent, setter]);

  const visibleTo = VisibleTo(content.destinations, role, content.parent?.profile, content.parent?.profileWeight);
  const propsTo = PropsTo(content.destinations);
  const [search, setSearch] = useState();
  const [data, setData] = useState();

  const onBlurTitle = async (value) => {
    const dataMemo = await Actions.findParents(search, content, disabled);
    setData(dataMemo);
  }

  return (
    <>
      <Col lg={{ span: 4, order: 2 }}>
        <Row>
          {visibleTo('status') && (
            <Col xs="12">
              <Form.Label>Estado</Form.Label> {statusList[content.status]}
            </Col>
          )}

          {visibleTo('acceptChildren') && content.hasChildren && content.hasChildren.total > 0 && (
            <Col xs="12">
              <Form.Label>Acepta sub contenidos</Form.Label> {content.acceptChildren ? 'SI' : 'NO'}
            </Col>
          )}

          {visibleTo('tags') && (
            <Col xs="12">
              <Form.Label>Tags</Form.Label>
              <Tags {...{ content, setter, disabled }} />
            </Col>
          )}

          {visibleTo('destinations') && (
            <Col xs="6">
              <Form.Label>Origen de información</Form.Label>
              <Destinations
                disabled={disabled || content.parent !== undefined}
                value={content.destinations}
                onChange={destinations => setter({ destinations })}
              />
            </Col>
          )}

          {visibleTo('profiles') && (
            <Col xs="6">
              <Form.Label>Perfiles</Form.Label>
              <ProfilesCheck
                disabled={disabled || content.parent !== undefined}
                value={content.profile}
                onChange={profileId => setter({ profile: profileId })}
              />
            </Col>
          )}

          {visibleTo('dateTime') && (
            <Col xs="12">
              <Form.Label>Fecha</Form.Label>
              <DateTime
                timeFormat={false}
                inputProps={{ disabled }}
                value={content.dateTime}
                onChange={dateSelected =>
                  dateSelected && dateSelected.toDate && setter({ dateTime: dateSelected.toDate() })
                }
                strictParsing={true}
                closeOnTab={true}
                {...propsTo('dateTime')}
              />
            </Col>
          )}

          {visibleTo('assessment') &&
            false && ( //Removido Ticket 657
              <Col xs="12">
                <Form.Label>Valoración</Form.Label>
                <Assessment
                  disabled={disabled}
                  value={content.assessment}
                  onChange={assessment => setter({ assessment })}
                />
              </Col>
            )}

          {visibleTo('hasPN') && (
            <Col xs="12">
              <Form.Group>
                <Form.Check
                  disabled={disabled}
                  type="checkbox"
                  label="Enviar Push Notification"
                  checked={content.hasPN}
                  onChange={evt => setter({ hasPN: evt.target.checked })}
                />
              </Form.Group>
            </Col>
          )}

          {visibleTo('imageFileName') && (
            <Col xs="12" md="6">
              <Form.Label>Imagen</Form.Label>
              <FileUpload
                disabled={disabled}
                accept="image/png , image/jpeg , image/jpg"
                value={content.imageFileName}
                onChange={imageFile => setter({ imageFile })}
                onCleanFile={() => setter({ imageFile: undefined, imageFileName: undefined })}
                forbiddenMsg={'Solo puede agregar imagenes con extensión jpg y png'}
              />
            </Col>
          )}

          {visibleTo('attachFileName') && (
            <Col xs="12" md="6">
              <Form.Label>Adjunto</Form.Label>
              <FileUpload
                disabled={disabled}
                accept=".doc,.docx,.pptx,.xlsx, application/pdf"
                value={content.attachFileName}
                onChange={attachFile => setter({ attachFile })}
                onCleanFile={() => setter({ attachFile: undefined, attachFileName: undefined })}
                forbiddenMsg={'Solo puede agregar archivos con extensión pdf'}
              />
            </Col>
          )}
        </Row>
      </Col>

      <Col lg="8">
        <Row>
          {visibleTo('title') && (
            <Col xs="12">
              <Form.Label>Título</Form.Label>
              <CharacterCounter {...{ content, setter, disabled, onBlurTitle}} limit={200} />
            </Col>
          )}

          {visibleTo('parent') && (!content.hasChildren || content.hasChildren.total === 0) && (
            <Col xs="12">
              <Parent {...{ content, setter, disabled, data, setSearch }} />
            </Col>
          )}

          {visibleTo('children') && content.hasChildren && content.hasChildren.total > 0 && (
            <Col xs="12">
              <Form.Label>Hijos</Form.Label>
              <Row>
                {_.map(content.hasChildren.list, child => {
                  return (
                    <Col xs="12" key={child._id}>
                      <Button variant="link" href={`/contents/${child._id}`}>
                        {child.title} - {child.dateTimeString}
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          )}

          {visibleTo('summary') && (
            <Col xs="12">
              <Form.Label>Resumen</Form.Label>
              <RichEditor disabled={disabled} value={content.summary || ''} onChange={summary => setter({ summary })} limit={400} />

            </Col>
          )}

          {visibleTo('text') && (
            <Col xs="12">
              <Form.Label>Descripción</Form.Label>
              <RichEditor disabled={disabled} value={content.text || ''} onChange={text => setter({ text })} />
            </Col>
          )}

          {visibleTo('sourceLink') && (
            <Col xs="12">
              <Form.Group>
                <Form.Label>Link</Form.Label>
                <Form.Control
                  disabled={disabled}
                  type="text"
                  value={content.sourceLink}
                  onChange={evt => setter({ sourceLink: evt.target.value })}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};
