import React, { useState } from 'react';
import { css } from 'emotion';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './RichEditor.scss';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();

  }
}

Quill.register('modules/clipboard', PlainClipboard, true);

const truncateWithHTML = (string, length) => {
  const noHTML = string.replace(/<[^>]*>/g, '');
  // if the string does not contains tags
  if (noHTML.length === string.length) {
    return `<p>${string.substring(0, length).trim()}</p>`;
  }

  const substrings = string.split(/(<[^>]*>)/g).filter(Boolean);
  let count = 0;
  let truncated = [];
  for (let i = 0; i < substrings.length; i++) {
    let substr = substrings[i];
    // if the substring isn't an HTML tag
    if (!substr.startsWith('<')) {
      if (count > length) {
        continue;
      } else if (substr.length > length - count - 1) {
        truncated.push(substr.substring(0, length - count ));
      } else {
        truncated.push(substr);
      }
      count += substr.length;
    } else {
      truncated.push(substr);
    }
  }

  return `<p>${truncated.join('')}</p>`;
};

const cssContainer = css`
  width: 100%;
`;

export default ({ value, onChange, disabled, limit = 0 }) => {
  const [count, setCount] = useState(value.replace(/<[^>]+>/g, '').length);
  const cssCounter = css`
    color: #8f9498;
    font-size: 1em;
    float: right;
  `;

  const setContent = evt => {
    let cleanCharacters = evt.replace(/<[^>]+>/g, '').length;
    if (limit > 0) {
      /** si el limite esta estalecido  */
      if (cleanCharacters <= limit) {
        onChange(evt);
        setCount(cleanCharacters);
      } else {
        //onChange(truncateWithHTML(evt, limit));
        onChange(truncateWithHTML(evt, limit));
      }
    } else {
      onChange(evt);
    }
  };

  return (
    <>
      <ReactQuill  theme="snow" value={value} onChange={setContent} className={cssContainer} readOnly={disabled} />

      {limit > 0 && (
        <span className={cssCounter}>
          {' '}
          {count}/{limit}
        </span>
      )}
    </>
  );
};
