import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import Api from '../Api';

import { Row, Col, Form } from 'react-bootstrap';

export default React.memo(({ disabled, value, onChange }) => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    loadProfiles();
  }, []);

  const loadProfiles = async () => {
    try {
      const res = await Api.private.get('profiles');
      const profiles = res.list.sort((a, b) => (a.weight > b.weight ? 1 : -1));
      setProfiles(profiles);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Row>
      <Col xs="12">
        {_.map(profiles, profile => {
          const profileKey = profile._id;

          return (
            <Form.Group key={profileKey}>
              <Form.Check
                disabled={disabled}
                type="checkbox"
                label={profile.name}
                checked={value === profileKey}
                onChange={evt => {
                  if (evt.target.checked) {
                    onChange(profileKey);
                  } else {
                    onChange(undefined);
                  }
                }}
              />
            </Form.Group>
          );
        })}
      </Col>
    </Row>
  );
});
