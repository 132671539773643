import { createCRUD } from './utils';
import * as Api from '../Api';

const model = 'roles';

const ACTIONS = {
  FIND_ALL: `${model}FIND_ALL`,
  GET: `${model}GET`,
  ADD_PRESETS: `${model}ADD_PRESETS`
};

export const initialState = {
  [model]: {
    list: undefined,
    total: undefined,
    selected: undefined,
    presets: undefined
  }
};

export const reducer = (state, { type, payload } = {}) => {
  if (type === ACTIONS.FIND_ALL) {
    return {
      ...state,
      [model]: { ...state[model], list: payload.list, total: payload.total }
    };
  }

  if (type === ACTIONS.GET) {
    return {
      ...state,
      [model]: { ...state[model], selected: payload }
    };
  }

  if (type === ACTIONS.ADD_PRESETS) {
    return {
      ...state,
      [model]: { ...state[model], presets: payload }
    };
  }

  return state;
};

const { findAll, getOne, cleanSelected, create, remove, update } = createCRUD(model, ACTIONS);

const getPresets = async dispatch => {
  const payload = await Api.findAll('roles/presets');
  dispatch({ type: ACTIONS.ADD_PRESETS, payload });
};

export default {
  findAll,
  getOne,
  cleanSelected,
  create,
  remove,
  update,
  getPresets
};
