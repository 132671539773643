import _ from 'lodash';
import React from 'react';
import { css } from 'emotion';

import { Row, Col } from 'react-bootstrap';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const assessmentList = ['Positiva', 'Neutra', 'Negativa'];

export const assessmentDefault = assessmentList[1];

const cssActive = css`
  background: #2fa4e7 !important;
  color: white !important;
`;

export default ({ disabled, value, onChange }) => {
  return (
    <Row>
      <Col xs="12">
        <ToggleButtonGroup name="assessment" type="radio" {...{ disabled, value, onChange }}>
          {_.map(assessmentList, assessment => (
            <ToggleButton
              variant="outline-primary"
              key={assessment}
              value={assessment}
              disabled={disabled}
              className={disabled && assessment === value ? cssActive : undefined}
            >
              {assessment}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>{' '}
      </Col>
    </Row>
  );
};
