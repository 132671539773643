import React from 'react';
import moment from 'moment';

import ListPage from '../../components/admin/ListPage';
import ElementPage from '../../components/admin/ElementPage';
import InformPanel from './InformPanel';

const model = 'inform';

const statusList = {
  '': 'Todos los estados',
  Pendiente: 'Pendiente',
  Publicado: 'Publicado',
  Despublicado: 'Despublicado'
};

const columns = [
  {
    text: 'Nombre',
    dataField: 'name'
  },
  {
    id: 'status',
    text: 'Estado',
    dataField: elm => statusList[elm.status],
    sort: 'status'
  },
  {
    id: 'startDate',
    text: 'Fecha Inicio',
    dataField: elm => moment(elm.startDate).format('DD/MM/YYYY HH')
  },
  {
    id: 'endDate',
    text: 'Fecha Fin',
    dataField: elm => moment(elm.endDate).format('DD/MM/YYYY HH')
  }
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} path={`/${model}`} statusList={statusList} />, // LIST
  <ElementPage key={`${model}_create`} model={model} Panel={InformPanel} path={`/${model}/create`} />, // CREATE
  <ElementPage key={`${model}_edit`} model={model} Panel={InformPanel} path={`/${model}/:_id`} /> // EDIT
];
