import { createCRUD } from './utils';

const model = 'tags';

const ACTIONS = {
  FIND_ALL: `${model}FIND_ALL`,
  GET: `${model}GET`
};

export const initialState = {
  [model]: {
    list: undefined,
    total: undefined,
    selected: undefined
  }
};

export const reducer = (state, { type, payload } = {}) => {
  if (type === ACTIONS.FIND_ALL) {
    return {
      ...state,
      [model]: {
        ...state[model],
        list: payload.list,
        total: payload.total
      }
    };
  }

  if (type === ACTIONS.GET) {
    return {
      ...state,
      [model]: {
        ...state[model],
        selected: payload
      }
    };
  }

  return state;
};

const { findAll, getOne, cleanSelected, create, remove, update } = createCRUD(model, ACTIONS);

export default {
  findAll,
  getOne,
  cleanSelected,
  create,
  remove,
  update
};
