import _ from 'lodash';
import React, { useState } from 'react';

import MdEye from 'react-ionicons/lib/MdEye';

import './index.scss';

export default ({
  disabled,
  data,
  columns,
  page,
  pages,
  onPageChange,
  onRowSelected,
  onSortChange,
  notPagination,
  notEye,
  multiSelectList
}) => {
  const [sort, setSort] = useState();
  const [sortOrder, setSortOrder] = useState('top');

  const visiblePages = [];
  const start = page > 2 ? page - 2 : 0;
  const end = start + 5 > pages ? pages : start + 5;
  for (let i = start; i < end; i++) {
    visiblePages.push(i);
  }

  const _onSortChange = dataField => {
    if (!dataField) return;

    let _sortOrder = 'top';

    if (sort === dataField) {
      _sortOrder = sortOrder === 'top' ? 'bottom' : 'top';
      setSortOrder(_sortOrder);
    } else {
      _sortOrder = 'top';
      setSortOrder(_sortOrder);
      setSort(dataField);
    }

    onSortChange && onSortChange(`${_sortOrder === 'top' ? '-' : ''}${dataField}`);
  };

  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr className="table-info">
            {!notEye && <th scope="col"></th>}

            {_.map(columns, column => {
              const _sort = column.id !== undefined || column.sort ? column.sort : column.dataField;
              return (
                <th
                  key={column.id || column.dataField}
                  scope="col"
                  className={`${_sort && onSortChange ? 'order' : ''} ${
                    sort !== undefined && _sort === sort ? `active-${sortOrder}` : ''
                  }`}
                  onClick={() => _sort && _onSortChange(_sort)}
                >
                  {column.text}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(data || [], (element, idx) => {
            return (
              <tr key={idx} onClick={() => !disabled && notEye && onRowSelected && onRowSelected(element)}>
                {!notEye && (
                  <td className="eye" onClick={() => !disabled && onRowSelected && onRowSelected(element)}>
                    <MdEye />
                  </td>
                )}

                {_.map(columns, column => {
                  if (_.isFunction(column.dataField) && !column.id)
                    throw new Error(`column ${column.text} required ID`);

                  return (
                    <td key={`${column.id || column.dataField}${idx}`}>
                      {_.isFunction(column.dataField)
                        ? column.dataField(element, data, multiSelectList)
                        : _.get(element, column.dataField)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!notPagination && data && data.length > 0 && (
        <div className="pagination-container">
          <ul className="pagination">
            <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => onPageChange(page - 1)}>
                &laquo;
              </button>
            </li>

            {start > 0 && (
              <li className="page-item">
                <button disabled={true} className="page-link">
                  ...
                </button>
              </li>
            )}

            {_.map(visiblePages, (p, i) => (
              <li key={i} className={`page-item ${p === page ? ' active' : ''}`}>
                <button className="page-link" onClick={() => p !== page && onPageChange(p)}>
                  {p + 1}
                </button>
              </li>
            ))}

            {end < pages && (
              <li className="page-item">
                <button disabled={true} className="page-link">
                  ...
                </button>
              </li>
            )}

            <li className={`page-item ${page === pages - 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => onPageChange(page + 1)}>
                &raquo;
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
