import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Form } from 'react-bootstrap';

import Scopes from '../../Services/Scopes';
import Roles from '../../Services/Roles';
import { required, validVersion } from '../../helper/Form';

import RichEditor from '../../components/RichEditor/RichEditor';

const initial = {};

export default forwardRef(({ selected, dispatch, state }, ref) => {
  const [elm, setElement] = useState(initial);

  const setter = data => setElement(prevElm => ({ ...prevElm, ...data }));
  const evt =
    (field, key = 'value') =>
    evt =>
      setter({ [field]: evt.target[key] });

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let result = 0;
      result += required(elm.versionNumber, 'El Número de Versión es requerido');
      result += validVersion(elm.versionNumber);
      result += required(elm.linkIOS, 'El link iOS es requerido');
      result += required(elm.linkAndroid, 'El link Android es requerido');
      return result;
    },

    getParams: async () => {
      return elm;
    }
  }));

  useEffect(() => {
    if (selected) {
      setElement(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (dispatch && (!state.roles.presets || !Object.keys(state.roles.presets).length)) {
      Roles.getPresets(dispatch);
    }
  }, [dispatch, state.roles.presets]);

  useEffect(() => {
    if (dispatch && !state.scopes.list) {
      Scopes.findAll(dispatch, { pagination: false });
    }
  }, [dispatch, state.scopes.list]);

  return (
    <>
      <Col xs="12">
        <Form.Label>Número de Versión</Form.Label>
        <Form.Control
          disabled={selected !== undefined}
          type="text"
          value={elm.versionNumber}
          onChange={evt('versionNumber')}
        />
      </Col>

      <Col xs="12">
        <Form.Group>
          <Form.Check
            disabled={selected !== undefined}
            type="checkbox"
            label="Enviar Push Notification"
            checked={elm.hasPN}
            onChange={evt('hasPN', 'checked')}
          />
        </Form.Group>
      </Col>

      <Col xs="12">
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Es Obligatorio"
            checked={elm.isMandatory}
            onChange={evt('isMandatory', 'checked')}
          />
        </Form.Group>
      </Col>

      <Col xs="12">
        <Form.Label>Link iOS</Form.Label>
        <Form.Control type="text" value={elm.linkIOS} onChange={evt('linkIOS')} />
      </Col>

      <Col xs="12">
        <Form.Label>Link Android</Form.Label>
        <Form.Control type="text" value={elm.linkAndroid} onChange={evt('linkAndroid')} />
      </Col>

      <Col xs="12">
        <Form.Label>Detalles</Form.Label>
        <RichEditor
          disabled={selected !== undefined}
          value={elm.changelog || ''}
          onChange={changelog => setter({ changelog })}
        />
      </Col>
    </>
  );
});
